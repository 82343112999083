<template>
  <div>
    <div class="contain1">
      <div class="contain1_top">
        <div class="h1">订单信息</div>
        <div class="action">
          <div @click="cancelOrder()">
            <img src="@/assets/image/icon82.png" alt="" />关闭订单
          </div>
          <div @click="goList()">
            <img src="@/assets/image/icon83.png" alt="" />订单列表
          </div>
        </div>
      </div>
      <div class="contain1_line"></div>
      <div class="contain1_tip">
        <img src="@/assets/image/icon65.png" alt="" class="contain1_tip_left" />
        <div class="contain1_tip_right">
          <div>温馨提示</div>
          <div>
            请在<span>{{ info.pay_expire_time | formatDate }}</span
            >前完成支付，否则订单将自动关闭距离订单关闭还有<span
              v-if="countdown !== 'N/A'"
              >{{ countdown }}</span
            >
          </div>
        </div>
      </div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon84.png" alt="" />
                商品名称
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ JSON.parse(info.config).vip_name }}
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon85.png" alt="" />
                订单号
              </div>
            </template>
            <template>
              <div class="info_style1">{{ info.order_no }}</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon86.png" alt="" />
                下单时间
              </div>
            </template>
            <template>
              <div class="info_style1">{{ info.create_time }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon87.png" alt="" />
                订单状态
              </div>
            </template>
            <template>
              <div
                class="info_style1"
                style="color: #ff0000"
                v-if="info.status == 1"
              >
                待支付
              </div>
              <div
                class="info_style1"
                style="color: #67c23a"
                v-if="info.status == 2"
              >
                已支付
              </div>
              <div class="info_style1" v-if="info.status == 3">已关闭</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon88.png" alt="" />
                购买数量
              </div>
            </template>
            <template>
              <div class="info_style1">{{ info.count }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon89.png" alt="" />
                商品单价
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">
                {{ info.price_old||0 }}元
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1" border>
          <el-descriptions-item
            labelClassName="label2"
            contentClassName="contentClassName2"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon89.png" alt="" />
                实付金额
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">
                {{ info.price||0 }}元
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="contain2">
      <div class="h1">商品信息</div>
      <div class="contain2_line"></div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon90.png" alt="" />
                商品名称
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ JSON.parse(info.config).vip_name }}
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon91.png" alt="" />
                VIP专享折扣
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ (JSON.parse(info.config).discount).toFixed(1) }}折
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <!-- <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon92.png" alt="" />
                身份标识
              </div>
            </template>
            <template>
              <div class="info_style1">——</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon93.png" alt="" />
                清爽去广告
              </div>
            </template>
            <template>
              <div class="info_style1">——</div>
            </template>
          </el-descriptions-item>
        </el-descriptions> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
import { get, postJSON } from "/utils/request";
export default {
  name: "vipOrder",
  props: {
    order_no: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      info: {},
      currentTimestamp: Math.floor(Date.now() / 1000), // 当前时间戳
      targetTimestamp: "",
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#f6f7f8";
    this.getOrderDetail();
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  computed: {
    countdown() {
      if (!this.targetTimestamp || isNaN(this.targetTimestamp)) {
        return "N/A";
      }
      const difference = this.targetTimestamp - this.currentTimestamp;
      if (difference <= 0) {
        // return "已过期"&&this.goList();
        return this.orderDetail();
      } else {
        const days = Math.floor(difference / (24 * 3600));
        const hours = Math.floor((difference % (24 * 3600)) / 3600);
        const minutes = Math.floor((difference % 3600) / 60);
        const seconds = difference % 60;
        return `${days}天 ${hours}时 ${minutes}分 ${seconds}秒`;
      }
    },
  },
  methods: {
    goList() {
      this.$router.push({
        path: "/personalCenter",
        query: { type: 10 },
      });
    },
    getOrderDetail() {
      console.log(this.$route.query.order_no);
      get("/order/info", { order_no: this.$route.query.order_no }).then(
        (res) => {
          console.log(res);
          this.info = res.data;
          this.targetTimestamp = res.data.pay_expire_time;
          if (this.targetTimestamp) {
            setInterval(() => {
              this.currentTimestamp = Math.floor(Date.now() / 1000);
            }, 1000);
          }
        }
      );
    },
    orderDetail() {
      // 先去详情 先不支付
      this.$router.push({
        path: "/orderDetail",
        query: {
          order_no: this.info.order_no,
          type: this.info.type,
        },
      });
    },
    cancelOrder() {
      postJSON("/order/cancel", { order_no: this.$route.query.order_no }).then(
        (res) => {
          console.log(res);
          if (res.message == "success") {
            this.$message.success("取消成功");
            this.$router.push({
              path: "/personalCenter",
              query: {
                type: 10,
              },
            });
          } else {
            this.$message.error("取消失败");
          }
        }
      );
    },
  },
};
</script>
  
<style lang="scss" scoped>
.contain1 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  .contain1_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h1 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
    }
    .action {
      display: flex;
      align-items: center;
      cursor: pointer;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #999999;
        letter-spacing: 0.9px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
      div:nth-child(2) {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        letter-spacing: 0.9px;
        margin-left: 20px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }
  }
  .contain1_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
  .contain1_tip {
    height: 96px;
    background: #fcf5ea;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .contain1_tip_left {
      width: 32px;
      height: 32px;
      margin-right: 20px;
      margin-bottom: 6px;
    }
    .contain1_tip_right {
      div:nth-child(1) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #edb738;
        letter-spacing: 0.8px;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #edb738;
        letter-spacing: 0.8px;
        span {
          font-size: 20px;
          color: #ff0000;
        }
      }
    }
  }
}
.contain2 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }
  .contain2_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
}
</style>
  <style lang="scss">
.label1 {
  width: 287px;
  height: 85px;
}
.contentClassName1 {
  width: 394px;
}
.label2 {
  width: 287px;
  height: 85px;
}
.contentClassName2 {
  width: 1074px;
}
.label_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.7px;
  margin-left: 20px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
  }
}
.info_style1 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.7px;
  margin-left: 20px;
}
</style>