<template>
  <div>
    <vipOrder v-if="isVip == 2" :order_no="order_no"></vipOrder>
    <docOrder v-if="isVip == 1" :order_no="order_no"></docOrder>
    <div class="contain3">
      <div class="h1">支付方式</div>
      <div class="contain3_line"></div>
      <div class="list">
        <div class="list_item" v-for="(item, index) in payMethod" :key="index">
          <div @click="current = item.id">
            <img src="@/assets/image/icon62.png" alt="" v-if="current == item.id" class="img1" /><img
              src="@/assets/image/icon63.png" alt="" v-else class="img1" />
          </div>
          <div class="title">{{ item.name }}</div>
          <div>
            <img :src="item.img" alt="" v-if="item.img" class="img2" />
            <div v-else class="title2">剩余{{ yue_price || 0 }}元</div>
          </div>
        </div>
      </div>
      <div class="btn_contain">
        <div>{{ isVip == 1 ? order.price || 0 : info.price || 0 }}元</div>
        <div @click="pay()">
          <img src="@/assets/image/icon36.png" alt="" />支付订单
        </div>
      </div>
    </div>
    <el-dialog title="微信支付" :visible.sync="isWxpay" width="30%">
      <div class="wxpay" style="display: flex;justify-content: center">
        <vue-qr ref="qrCode" :text="wxlink" :size="200"></vue-qr>
      </div>
    </el-dialog>
    <el-dialog title="支付宝支付" :visible.sync="isAlipay" width="30%">
      <!-- <div class="wxpay" style="display: flex;justify-content: center">
        <vue-qr ref="qrCode" :text="wxlink" :size="200"></vue-qr>
      </div> -->
      <!-- <iframe id="alipayFrame"></iframe> -->
      <vue-qr ref="qrCode1" :text="alilink" :size="200"></vue-qr>

    </el-dialog>
  </div>
</template>

<script>
import vipOrder from "@/components/vipOrder.vue";
import docOrder from "@/components/docOrder.vue";
import { get } from "/utils/request";
import { postJSON } from "../../../utils/request";
import VueQr from "vue-qr";

export default {
  components: {
    vipOrder,
    docOrder,
    VueQr,

  },
  data() {
    return {
      payMethod: [
        { id: 3, name: "余额支付", img: "" },
        { id: 1, name: "微信支付", img: require("@/assets/image/icon60.png") },
        {
          id: 2,
          name: "支付宝支付",
          img: require("@/assets/image/icon61.png"),
        },
      ],
      current: 3,
      isVip: 0, //2购买vip 1购买文档
      order_no: this.$route.query.order_no,
      document: {}, //doc详情
      order: {}, //doc订单信息
      yue_price: 0,
      isWxpay: false,
      wxlink: '',
      isAlipay: false,
      myStateInterval: null,
      alilink: ''
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#f6f7f8";
    this.getOrderDetail();
    this.getUserInfo()
    // this.yue_price = JSON.parse(window.localStorage.getItem("userInfo")).price; //余额
  },
  destroyed() {
    clearInterval(this.myStateInterval);
  },
  methods: {
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      console.log("res", res);
      this.yue_price = res.data.price;//余额
    },
    getOrderDetail() {
      console.log(this.$route.query.order_no);
      get("/order/info", { order_no: this.$route.query.order_no }).then(
        (res) => {
          console.log(res);
          console.log(typeof res.data);

          if (!res.data.document && !res.data.order) {
            this.isVip = res.data.type;
            this.info = res.data;
          } else {
            this.isVip = res.data.order.type;
            this.document = res.data.document;
            this.order = res.data.order;
          }
          // if (res.data.order.type == 1) {
          //   // 文档order
          //   this.isVip = res.data.order.type;
          //   this.document = res.data.document;
          //   this.order = res.data.order;
          // } else {
          //   // viporder
          //   this.isVip = res.data.type;
          //   this.info = res.data;
          // }
        }
      );
    },

    pay() {
      // 1文档下单  2vip下单
      // if(this.current==1){
      //   this.isWxpay = true
      //   return
      // }
      if (this.isVip == 1) {
        console.log('this.isVip == 1');
        // 选择支付方式
        // 是否支付成功
        // 成功后直接下载文档 跳转到订单详情页
        // 测试地址
        // postJSON("/GetPayCode", {
        //   order_no: this.order_no,
        //   pay_channel: this.current,
        // }).then((res) => {
        //   console.log('生成订单返回信息', res);
        //   if (res.message == "success") {
        //     if (this.current == 1) {
        //       // 微信扫码支付
        //       this.isWxpay = true
        //       this.wxlink = res.data.code_url
        //       // this.getOrderStatus(this.order_no, 1)//轮询订单状态 参数1订单号 参数2订单类型（1文档 2购买vip）
        //       return
        //     }
        //     if (this.current == 2) {
        //       // 支付宝
        //       return

        //     }
        //     if (this.current == 3) {
        //       // 余额支付

        //       this.downloadFile();
        //       setTimeout(() => {
        //         this.$router.push({
        //           path: "/orderDetail",
        //           query: {
        //             order_no: this.order_no,
        //             type: 1,
        //           },
        //         });
        //       });
        //       return

        //     }

        //   }
        // });
        postJSON("/pay", {
          order_no: this.order_no,
          pay_channel: this.current,
        }).then((res) => {
          console.log('生成订单返回信息', res);
          if (res.message == "success") {
            if (this.current == 1) {
              // 微信扫码支付
              this.isWxpay = true
              this.wxlink = res.data.code_url
              console.log(this.isWxpay, this.wxlink);
              this.getOrderStatus(this.order_no, 1)//轮询订单状态 参数1订单号 参数2订单类型（1文档 2购买vip）
              return
            }
            if (this.current == 2) {
              // 支付宝
              // this.isAlipay = true;
              window.location.replace(res.data)
              // this.alilink = res.data
              // this.getOrderStatus(this.order_no, 1)


            }
            if (this.current == 3) {
              // 余额支付

              this.downloadFile();
              setTimeout(() => {
                this.$router.push({
                  path: "/orderDetail",
                  query: {
                    order_no: this.order_no,
                    type: 1,
                  },
                });
              });
            }

          }
        });
      } else {
        console.log('this.isVip == 1---else');

        // 是否支付成功
        // 成功后跳转到vip订单详情页

        postJSON("/pay", {
          order_no: this.order_no,
          pay_channel: this.current,
        }).then((res) => {
          console.log('生成订单返回', res);
          if (res.message == "success") {
            if (this.current == 1) {
              // 微信扫码支付
              this.isWxpay = true
              this.wxlink = res.data.code_url
              console.log(this.isWxpay, this.wxlink);
              this.getOrderStatus(this.order_no, 2)//轮询订单状态 参数1订单号 参数2订单类型（1文档 2购买vip）
              return
            }
            if (this.current == 2) {
              // 支付宝
              // this.isAlipay = true;
              window.location.replace(res.data)
              // this.alilink = res.data
              // this.getOrderStatus(this.order_no, 1)


            }
            if (this.current == 3) {
              this.$router.push({
                path: "/orderDetail",
                query: {
                  order_no: this.order_no,
                  type: 2,
                },
              });
            }
          }
        });
        // get("/GetPayCode",{}).then((res)=>{
        //   console.log('GetPayCode',res);
        // })
      }
    },
    // 获取订单支付状态
    getOrderStatus(order_no, type) {
      this.myStateInterval = setInterval(async () => {
        // 查询订单状态
        let res = await get("/order/info", { order_no })
        if (type == 1) {
          if (res.data.order.status == 2) {
            this.$message.success("支付成功");
            clearInterval(this.myStateInterval);
            this.isWxpay = false
            this.downloadFile();
            setTimeout(() => {
              this.$router.push({
                path: "/orderDetail",
                query: {
                  order_no,
                  type
                },
              });
            }, 1500);

          }
          return
        }
        if (type == 2) {
          if (res.data.status == 2) {
            this.$message.success("支付成功");
            clearInterval(this.myStateInterval);
            this.isWxpay = false
            // this.downloadFile();
            setTimeout(() => {
              this.$router.push({
                path: "/orderDetail",
                query: {
                  order_no,
                  type
                },
              });
            }, 1500);

          }
          return
        }

      }, 3000)
    },
    downloadFile() {
      let is_vip = window.localStorage.getItem("userInfo").is_vip;
      // != 1
      if (is_vip != 1) {
        get("/document/download", { id: Number(this.document.id) }).then(
          (res) => {
            console.log(res);
            window.location.href = `${this.$imgURL + res.url}`;
          }
        );
      } else {
        get("/document/download/vip", { id: Number(this.document.id) }).then(
          (res) => {
            console.log(res);
            window.location.href = `${this.$imgURL + res.url}`;
          }
        );
      }
      this.$router.push({
        path: "/orderDetail",
        query: {
          order_no: this.order_no,
          type: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain3 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto 50px;
  padding: 20px;
  box-sizing: border-box;

  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }

  .contain3_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }

  .list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .list_item {
      // width: 249px;
      // height: 65px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      .img1 {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 20px;
      }

      .img2 {
        width: 25px;
        height: 25px;
      }

      .title {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
        letter-spacing: 0.9px;
        margin-right: 20px;
        line-height: 65px;
      }

      .title2 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.8px;
      }
    }
  }

  .btn_contain {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #fa0000;
      letter-spacing: 0.7px;
      margin-right: 20px;
    }

    div:nth-child(2) {
      cursor: pointer;
      width: 366px;
      height: 65px;
      background: linear-gradient(90deg, #23c48c, #33da61);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 0.9px;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
<style lang="scss">
.label1 {
  width: 287px;
  height: 85px;
}

.contentClassName1 {
  width: 394px;
}

.label2 {
  width: 287px;
  height: 85px;
}

.contentClassName2 {
  width: 1074px;
}

.label_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.7px;
  margin-left: 20px;

  img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
  }
}

.info_style1 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.7px;
  margin-left: 20px;
}
</style>